import React from "react";

const Experience = () => {
  return (
    <div id="experience" className="experience">
      <div className="d-flex justify-content-center my-1">
        <h1>experience</h1>
      </div>
      <div className="container experience-wrapper">
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>2002 to Oct-2005</h3>
            <h4>Software Developer</h4>
            <p>Worked as a Visual Basic developer with multiple startups including <a href="http://www.xpertt.com/" target="_blank" rel="noreferrer">MKC Software Pvt LTD</a>, NetZone in Delhi</p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>Sep-2005 to Oct-2007</h3>
            <h4>Team Leader: Software Development</h4>
            <p>Worked as a Software Developer and then Team Leader in <a href="https://ideafarms.com/" target="_blank" rel="noreferrer">Ideafarms</a> on Java and IBM Workflow Management tools</p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-right">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>Oct-2007 to Jan-2013</h3>
            <h4>Project Leader: Software Development</h4>
            <p>Worked in <a href="https://www.velocis.in/>" target="_blank" rel="noreferrer">Velocis Systems</a> as Team Lead, Project Leader for 7 years on IBM Lotus Domino, Java, Websphere and other related technologies</p>
          </div>
        </div>
        {/* - */}
        <div className="timeline-block timeline-block-left">
          <div className="marker"></div>
          <div className="timeline-content">
            <h3>Feb-2013 to Current</h3>
            <h4>Consultant and Corporate Trainer</h4>
            <p>Working as a <a href="https://www.linkedin.com/in/profileatingupta/" target="_blank" rel="noreferrer">Freelance Consultant</a>, Corporate Trainer on latest technologies like AI, DevOps, Data Engineering, Azure, AWS, Blockchain etc </p>
            <p>Delivered more than 120 corporate trainings</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience;

import React from 'react'
import author from '../me.png'

const AboutMe = () => {
  return (
    <div id='about' className='container py-5'>
      <div className='row'>
        <div className='col-lg-6 col-xm-12'>
          <div className='photo-wrap mb-5'>
            <img className='profile-img' src={author} alt='author...' />
          </div>
          <div className="profile_links">
            <div>
              <a href="https://docs.google.com/document/d/1T9J5AOFiuE8uoE4DubZ8WDJRtE_YxVncrbC1DtCUhS0" target="_blank" rel="noreferrer">Word Profile</a>
            </div>
            <div>
              <a href="https://www.linkedin.com/in/profileatingupta/" target="_blank" rel="noreferrer">LinkedIn Profile</a>
            </div>
          
            <div>
              <a href="https://drive.google.com/file/d/1G-a5dTsUU6XJObyq1eTGDyqby5FeSJRo/view?usp=sharing" target="_blank" rel="noreferrer">
              Certifications Transcript</a>
            </div>
          </div>
        </div>
        <div className='col-lg-6 col-xm-12'>
            <ul>
              <li>Having 20+ years of total IT experience in AI, Blockchain & Azure</li>
              <li>Working as a Freelance Consultant and developed multiple projects in Data Engineering, DevOps, Python, PySpark etc</li>
              <li>Delivered 120+ corporate trainings to top MNCs</li>
              <li>Microsoft Certified Trainer. Certification ID: 989990444</li>
              <li>Have expertise in various areas: Cloud technologies,DevOps, Artificial Intelligence, Data Engineering, Blockchain etc</li>
              <li>Certified in Azure for multiple technologies</li>
                <ul>
                  <li>Azure Security Engineer Associate</li>
                  <li>Azure Data Engineer Associate</li>
                  <li>Azure Developer Associate</li>
                  <li>Azure DevOps Engineer Expert</li>
                  <li>Azure AI Engineer Associate</li>
                  <li>Azure Data Scientist Associate</li>
                  <li>Azure Administrator Associate</li>
                </ul>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default AboutMe

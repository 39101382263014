import axios from 'axios';
import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import ClientBlock from './ClientBlock';

const Clients = () => {
  const [vals, setVals] = useState();

  const getData = async () => {
    const { data } = await axios(env.PROFILE_API_BASE_URL + "/api/clients");
    setVals(data);
  };

  useEffect(() => {
    getData();
  }, []);

  var clientBlocks = [];
  if (vals) {
    vals.map((val) => {
        //console.log(val);
        clientBlocks.push(
          <div key={val.client_name}>
              <ClientBlock client_details={val}/>
          </div>
        )
        return "";
    })
  }
  return (
    <div id="clients" className='container py-2'>
      <div className="d-flex justify-content-left my-1">
        <h1>Clients</h1>
      </div>

      <div className='row'>
        <div>
            <ol>
              {clientBlocks}
            </ol>
        </div>
      </div>
    </div>

    )
}

export default Clients
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faChalkboardTeacher, faServer } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <div id="services" className="services">
      <h1 className="py-3">my services</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle"><FontAwesomeIcon className="icon" icon={faDesktop} size="2x" /></div>
              <h3>Development and Consulting</h3>
              <p>I provide consulting and Development services of variety of latest technologies</p>
            </div>
          </div>
          {/* - */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle"><FontAwesomeIcon className="icon" icon={faChalkboardTeacher} size="2x" /></div>

              <h3>Trainings</h3>
              <p>I provide trainings on Azure, AWS, Python, AI, Blockchain, DevOps, Data Engineering, Data Science and other latest technologies</p>
            </div>
          </div>
          {/* - */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle"><FontAwesomeIcon className="icon" icon={faServer} size="2x" /></div>

              <h3>Cloud Labs</h3>
              <p>I provide cloud labs on Azure, AWS and GCP for training delivery or development</p>
            </div>
          </div>
          {/* - */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div className="circle"><FontAwesomeIcon className="icon" icon={faDesktop} size="2x" /></div>

              <h3>Support</h3>
              <p>I provide hourly support services on latest technologies</p>.
              </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Services;

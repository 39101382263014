import axios from 'axios';
import React, { useEffect, useState } from "react";
import ProjectDeliveredBlock from './ProjectDeliveredBlock';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from "react-icons/bs";
import PublicationBlock from './PublicationBlock';
import env from "react-dotenv";

const Portfolio = () => {
    const [vals_projects_delivereds, setVals_projects_delivereds] = useState();
    const [vals_publications, setVals_publications] = useState();

  const getData_projects_delivered = async () => {
    let { data } = await axios(env.PROFILE_API_BASE_URL + "/api/projects_delivereds");
    setVals_projects_delivereds(data);
  };

  const getData_publications = async () => {
    let { data } = await axios(env.PROFILE_API_BASE_URL + "/api/publications");
    setVals_publications(data);
  };
  
  useEffect(() => {
    getData_projects_delivered();
    getData_publications();
  }, []);

  
  var projectDeliveredBlocks = [];
  if (vals_projects_delivereds) {
      vals_projects_delivereds.map((val) => {
          //console.log(val);
          projectDeliveredBlocks.push(
          <div key={val.id}>
            <ProjectDeliveredBlock project_details={val} />
          </div>
      )
      return "";
      })
  }

  var publicationsBlocks = [];
  if (vals_publications) {
      vals_publications.map((val) => {
          //console.log(val);
          publicationsBlocks.push(
          <div key={val.id}>
            <PublicationBlock details={val} />
          </div>
      )
      return "";
      })
  }

  const publications_title = " Publications"
  const training_delivered__title = " Trainings Delivered"
  

  return (
      <div id='portfolio' className='container py-2'>
        <div className="d-flex justify-content-left my-1">
          <h1>Portfolio</h1>
        </div>

        <div className='row'>
          <div>
            <Collapsible trigger={[<BsChevronDown />, training_delivered__title]}>
              <ol>
                {projectDeliveredBlocks}
              </ol>
            </Collapsible>
          </div>
        </div>

        <div className='row'>
          <div>
            <Collapsible trigger={[<BsChevronDown />, publications_title]}>
              {publicationsBlocks}
            </Collapsible>
          </div>
        </div>

      </div>
  )
};

export default Portfolio;
const ProjectDeliveredBlock = (props) => {
   //console.log("ProjectDeliveredBlock props.project_details.name: ", props.project_details.id);
   let name = "";
   let client = "";
   let dates = "";
   let duration = "";

   if (props.project_details.name && props.project_details.name.trim() !== "")
      name = '"' + props.project_details.name + '"';

   if (props.project_details.client && props.project_details.client.trim() !== "")
      client = ' to "' + props.project_details.client + '"';

   if (props.project_details.dates && props.project_details.dates.trim() !== "")
      dates = " in " + props.project_details.dates;

   if (props.project_details.duration && props.project_details.duration.trim() !== "")
      duration = " (" + props.project_details.duration + ")";

   return (
      <li key={props.project_details.id.toString()}><p>{name} {client} {dates} {duration}</p></li>
   )
   }
  export default ProjectDeliveredBlock;
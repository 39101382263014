import axios from 'axios';
import React, { useEffect, useState } from "react";
import CertificateIssuerBlock from './CertificateIssuerBlock';
import env from "react-dotenv";

const Certifications = () => {
  const [vals, setVals] = useState();

  const getData = async () => {
    const { data } = await axios(env.PROFILE_API_BASE_URL + "/api/certifications");
    setVals(data);
  };

  useEffect(() => {
    getData();
  }, []);

  var dict_vals = {}
  if (vals)
  {
    //console.log(vals);
    vals.map((val) => {
        //console.log(val);
        if (!(val.issuer in dict_vals)) {
          dict_vals[val.issuer] = [];
          //console.log("first time");
        }
        dict_vals[val.issuer].push(val);
        return "";
    });
  }
  
  var certificateIssuerBlocks = [];
  Object.entries(dict_vals).map((key_vals) => {
      //console.log(key_vals[0], key_vals[1]);
      certificateIssuerBlocks.push(
        <div  key={key_vals[0]}>
          <CertificateIssuerBlock issuer={key_vals[0]} my_certifications={key_vals[1]}/>
        </div>
      )
      return "";
  })

  return (
    <div id="certifications" className="certifications">
      <div className="d-flex justify-content-center my-3">
        <h1>Certifications</h1>
      </div>
      <div className="container Certifications-wrapper">
        {certificateIssuerBlocks}

      </div>
        <br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br /><br /><br /><br /><br /><br /><br />
        <br /><br />
    </div>
  )
}

export default Certifications;

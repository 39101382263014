const CertificateDetailsBlock = (props) => {
  //console.log("props.my_certifications: ", props.my_certifications);
  var certificateDetailsBlocks = [];
  props.my_certifications.map((val) => {
     //console.log("val.title: ", val.title);
      certificateDetailsBlocks.push(
        <li><p key={val.id}>{val.title}</p></li>
      )
      return "";
  })

  return (
       <div>
          {certificateDetailsBlocks}
       </div>
  )

  }

  export default CertificateDetailsBlock;
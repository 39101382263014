import React from "react";
import Typed from "react-typed";
import { Link } from "react-scroll";


const Header = () => {
  return (
    <div id="home" className="header-wraper">
      <div className="main-info">
        <div className="profile_links">
          <div>
            <a href="https://docs.google.com/document/d/1T9J5AOFiuE8uoE4DubZ8WDJRtE_YxVncrbC1DtCUhS0" target="_blank" rel="noreferrer">Download Word Profile</a>
          </div>
          <div>
            <a href="tel:+919810707414">Call: +919810707414</a>
          </div>
        </div>
        <br /><br /><br />
        <h1>Want to work with me?</h1>
        <Typed
          className="typed-text"
          strings={["I need Part Time support on HTML, CSS, Web Designing, JavaScript"]}
          typeSpeed={20}
          backSpeed={200}
          loop
        />

        <Link smooth={true} to="contacts" offset={-110} className="btn-main-offer">contact me</Link>
      </div>
    </div>
  )
}

export default Header;

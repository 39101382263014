import Collapsible from 'react-collapsible';
import CertificateDetailsBlock from "./CertificateDetailsBlock";
import { BsChevronDown } from "react-icons/bs";

const CertificateIssuerBlock = (props) => {
    //console.log("props.issuer: ", props.issuer);
    return (
      <div>
        <div className="timeline-block timeline-block-left">
          <div className="timeline-content">
            <div className='collapsible'>
              <Collapsible className="header" trigger={[<BsChevronDown />, props.issuer]}>
                <ol>
                  <CertificateDetailsBlock my_certifications={props.my_certifications} />
                </ol>
              </Collapsible>
            </div>
          </div>
        </div>
        </div>
    )
    }
  export default CertificateIssuerBlock;